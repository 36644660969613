<template>
  <div class="login-box">
    <van-nav-bar title="微信授权中" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="login-con" v-if="goBing">
      <div class="icon-box">
        <img src="../../assets/login/logo.png" alt />
      </div>
      <van-form class="bing-form" @submit="goBingConfirm">
        <template>
          <div class="filed-box">
            <van-field
              v-model="bingForm.mobile"
              name="绑定手机号"
              clearable
              required
              placeholder="输入绑定手机号"
              :rules="[{ required: true }]"
            />
          </div>
          <div class="filed-box">
            <van-field
              v-model="bingForm.kaptcha"
              name="短信验证码"
              clearable
              required
              placeholder="输入短信验证码"
              :maxlength="6"
              :rules="[{ required: true }]"
            />
            <span
              :class="{ 'no-style': intervalId }"
              @click="sendPhoneVerifyRegist"
            >{{ sendNotice }}</span>
          </div>
          <div class="btn-box">
            <van-button round block type="info">绑定手机</van-button>
          </div>
        </template>
      </van-form>
    </div>
  </div>
</template>

<script>
import { isPhoneNum } from '../../utils/tool';
import { mapMutations } from 'vuex';
export default {
  name: 'Login',
  data() {
    return {
      bingForm: {
        mobile: null, //手机号,自动等于用户名
        kaptcha: null, //手机验证码
      },
      isWx: false,
      goBing: false,
      intervalId: null,
      sendNotice: '发送验证码',
      accept: '',
      imgVisible: true,
      imageCaptchaUrl: null,
      smsTemplateType: 'LOGIN', //验证类型("REGIST"|"LOGIN")
      codeObj: null,
    };
  },
  mounted() {
    this.isWx = /MicroMessenger/i.test(window.navigator.userAgent);
    if (location.search) {
      let query = this.qs(location.search);
      let href =
        window.location.origin +
        '/#/wxlogin?code=' +
        query.code +
        '&state=' +
        query.state;
      window.location.replace(href);
    }
    this.codeObj = this.$route.query
    if (this.codeObj.code) {
      this.$http
        .get('/selfhelpOfficialAccounts/wechat/login', {
          code: this.codeObj.code,
          state: this.codeObj.state,
        })
        .then(async (res) => {
          if (res.code === 98070) {
            this.$dialog
              .confirm({
                message: '当前微信未绑定手机号，请先绑定手机号！',
              })
              .then(() => {
                this.goBing = true;
              })
              .catch(() => {
                localStorage.setItem('userInfo', '');
                localStorage.setItem('accessToken', '');
                this.$router.push('/login');
              });
          } else if (res.code === 200) {
            //this.$toast('登录成功，跳转中!');
            this.loginSuccess(res);
          } else {
            this.$toast(res.message || '登录失败!');
            this.delCode();
          }
        });
    }
  },
  methods: {
    ...mapMutations('user', ['SET_ISLOGIN']),
    ...mapMutations('app', ['SET_TABBARACTIVE']),
    onClickLeft() {
      localStorage.setItem('userInfo', '');
      localStorage.setItem('accessToken', '');
      this.$router.push('/login');
    },
    qs(search) {
      var obj = {};
      var query = search.slice(1);
      query.split('&').forEach(function (value) {
        var arr = value.split('=');
        obj[arr[0]] = arr[1];
      });
      return obj;
    },
    goBingConfirm() {
      this.$http
        .get('/selfhelpOfficialAccounts/wechat/binding', {
          code: this.codeObj.code,
          state: this.codeObj.state,
          ...this.bingForm,
        })
        .then(async (res) => {
          if (res.code === 200) {
            this.$toast('绑定成功，跳转中!');
            this.loginSuccess(res);
          } else if (res.code === 98050) {
            this.$toast('手机号未注册，当前为你跳转去注册!');
            this.$router.push({
              path: '/register',
              query: { mobile: this.bingForm.mobile },
            });
          } else {
            this.$toast(res.message || '绑定失败!');
          }
        });
    },
    //发送手机验证码
    sendPhoneVerifyRegist() {
      // 未到60s
      if (this.intervalId) {
        return;
      }
      // 校验手机号是否正确.
      if (!isPhoneNum(this.bingForm.mobile)) {
        this.$toast('请输入正确的手机号!');
        return;
      }
      // 发送请求,获得手机验证码
      this.$http
        .post('/selfhelpOfficialAccounts/message/sms/send', {
          mobile: this.bingForm.mobile,
          smsTemplateType: this.smsTemplateType,
        })
        .then((res) => {
          if (res.code === 200) {
            this.downTime();
            this.$toast('验证短信发送成功!');
          } else {
            this.$toast(res.message || '发送失败!');
          }
        });
    },
    // 倒记时60秒
    downTime() {
      if (this.intervalId != null) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
      var time = 60;
      this.intervalId = setInterval(() => {
        time--;
        this.sendNotice = `再次发送(${time}s)`;
        if (time <= 0) {
          clearInterval(this.intervalId);
          this.intervalId = null;
          this.sendNotice = `发送验证码`;
        }
      }, 1000);
    },
    async loginSuccess(res) {
      let token = res.data.accessToken;
      localStorage.setItem('accessToken', token);
      this.SET_ISLOGIN(true);
      let dictionariesBack = await this.$http.get(
        '/selfhelpOfficialAccounts/dictionaries'
      );
      if (dictionariesBack.code === 200) {
        localStorage.setItem(
          'dictionaries',
          JSON.stringify(dictionariesBack.data)
        );
      }
      let back = await this.$http.get('/selfhelpOfficialAccounts/relation');
      if (back.code === 200) {
        localStorage.setItem('userInfo', JSON.stringify(back.data));
        let urlPath = null;
        switch (back.data.approveCodeEnum) {
          case 'UN_LINK':
            urlPath = '/noLegalize';
            break;
          case 'APPROVE_FAIL':
            urlPath = '/noPassAudit';
            break;
          case 'SECONED_WAITTING':
            urlPath = '/auditing';
            break;
          case 'WAITING':
            urlPath = '/auditing';
            break;
          case 'APPROVE_PASS':
            urlPath = '/passAudit';
            break;
          case 'PROCESS_DONE':
            urlPath = '/positionMgt/index';
            break;
          case 'COMPANY_STOP':
            urlPath = '/login';
            localStorage.setItem('userInfo', '');
            localStorage.setItem('accessToken', '');
            this.$toast('公司被禁用');
            break;
          case 'STOP':
            urlPath = '/login';
            localStorage.setItem('userInfo', '');
            localStorage.setItem('accessToken', '');
            this.$toast('账号禁用');
            break;
        }
        // 登录成功后跳转到主页
        if (back.data.approveCodeEnum === 'PROCESS_DONE') {
          this.SET_TABBARACTIVE(5);
        }
        this.$router.push({ path: urlPath, replace: true });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .login-con {
    flex: 1;
    overflow-y: auto;
    .icon-box {
      margin-top: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 30px;
      }
      img:first-child {
        width: 180px;
      }
      span {
        width: 1px;
        height: 20px;
        background: #e5e5e5;
        margin: 0 10px;
      }
    }
    .welcome {
      font-size: 21px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin: 60px 0 40px 0;
      text-align: center;
    }
    .van-cell {
      padding: 10px 16px;
      flex: 1;
      border-radius: 32px;
      background: #f7f7f7;
    }
    .van-cell::after {
      border-bottom: none;
    }
    .van-cell--required::before {
      display: none;
    }
    .filed-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 16px;
      background: #f7f7f7;
      border-radius: 32px;
      img {
        width: 77px;
        height: 23px;
      }
      span {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #206cfe;
        margin: 0 16px;
        text-align: left;
      }
      .no-style {
        color: #cccccc;
      }
    }
    .btn-box {
      margin-top: 40px;
      padding: 0 20px;
    }
    .radio-box {
      margin: 20px;
      ::v-deep .van-radio__label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
    .bing-form {
      margin-top: 60px;
    }
    .wx-login {
      margin-top: 110px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        height: 47px;
        width: 47px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
